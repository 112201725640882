.eventIconText,
.timeLeft {
    font-size: var(--fs-heading4);
    font-weight: var(--fw-medium)
}

.emphasizedText {
    font-weight: var(--fw-medium);
}

.allEventDetailsContainer {
    width: 80%;
    display: flex;
    gap: var(--xtra-xtra-large);
    place-content: center;
    justify-content: space-between;
    padding: var(--xtra-large) 0
}

.allEventDetailsContainer a {
    text-decoration: none;
    color: var(--primary-clr-dark);
    background-color: #e1e1e1;
    padding: var(--small);
    margin: var(--small) 0;
    border: 1px solid var(--alt-dark-clr);
    border-radius: var(--small);
    width: fit-content;
    transition: background-color 200ms ease-in-out;
}

.allEventDetailsContainer a:hover {
    background-color: var(--active-nav-clr);
}

.eventDetailsContainer {
    display: grid;
    gap: var(--medium);
}

.eventTitle {
    font-size: var(--fs-heading3);
    font-weight: var(--fw-medium);
}

.eventHeadline {
    font-size: var(--fs-heading4);
    font-weight: var(--fw-regular);
}

.eventDetailsGrouped {
    display: grid;
    gap: var(--big);
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    padding: var(--large);
    border: 1px solid var(--alt-dark-clr);
    border-radius: var(--small);
}

/* .eventDetailsGrouped .eventDetail {} */

.eventDetail {
    display: grid;
    height: fit-content;
    background-color: var(--primary-clr-light);
}

.eventDetailsBackBtn {
    width: 80%
}

.eventDetailsBackBtn button {
    background-color: var(--secondary-clr-btn)
}

.eventDetailsImgContainer {
    border-radius: var(--medium);
    overflow: hidden;
    height: min-content;
    box-shadow: rgba(36, 38, 36, 0.075) 0px 10px 20px 0px;
}

.eventDetailsImgContainer img {
    width: 100%;
}

.eventIconText {
    display: flex;
    gap: var(--xtra-small);
    margin-bottom: var(--xtra-small)
}

.countdownSection {
    width: 80%;
    margin: auto;
    padding: var(--xtra-large) 0;
    padding-top: var(--medium);
}

.countdownContainer {
    display: grid;
    width: 100%;
    gap: var(--medium);
    grid-column: 1/3;
    place-self: center;
    text-align: center;
    padding: var(--medium);
    padding-bottom: var(--large);
    color: var(--primary-clr-light);
    background: rgba(0, 0, 0, 0.849);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, .1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, .705)
}

.countdownCard {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(4.5rem, 1fr));
    gap: var(--small);
    color: #f98787
}

.timeLeft {
    font-size: var(--fs-heading1)
}

.sponsorsSection {
    display: grid;
    /* gap: var(--small); */
}

.sponsorsContainer {
    display: grid;
    gap: var(--large);
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

}

@media screen and (max-width:67.5rem) {
    .allEventDetailsContainer {
        display: flex;
        flex-direction: column
    }
}

@media screen and (max-width:33.5rem) {
    .allEventDetailsContainer {
        width: 90%;
    }

    .eventDetailsBackBtn {
        width: 90%;
    }

    .countdownSection {
        width: 90%;
    }
}